import React from "react";

export default function PVTResultsPopup(props) {
  if (props.visible) {
    console.log("Rendering results page with test result of: " + props.result);

    return (
      <div id={"pvtModalResults"}>
        <div id={"pvtResultsContainer"}>
          <PVTResultPage
            result={props.result}
            compensationCode={props.compensationCode}
          />
        </div>
      </div>
    );
  } else {
    return "";
  }
}

function PVTResultPage(props) {
  console.debug("pvt results page building", props.compensationCode);
  return (
    <div className="container-fluid" id="resultsPage">
      <h1 align="center">Test Results</h1>
      <div id="resultsContainer">
        <div className={"resultsRow"}>
          Your results have been successfully saved!
          <br />
          Your compensation code is{" "}
          <span className={"mturk-compensation-code"}>
            {props.compensationCode}
          </span>
          . Make sure to remember this code, as you will need to provide it to
          receive compensation. Thank you for completing the study. Please close
          this window to finish the test.
        </div>
        <hr />
        <div className="resultsRow">
          Test Time: {new Date(props.result.testTimestamp).toLocaleString()}
        </div>
        <div className="resultsRow">
          Your Average Response Time was:{" "}
          {props.result.meanRT().toFixed(2) + "ms"}
        </div>
      </div>
    </div>
  );
}

export class PVTReaction {
  constructor(
    reactionTime,
    testTimestamp,
    pvtTestDuration,
    falseStart,
    sleepAttack,
    practiceRun,
    isiExpectedDelay,
    isiActualDelay,
    isiStartTime,
    isiEndTime,
    longPressError = false,
  ) {
    this.timestamp = Date.now();
    this.reactionTime = reactionTime;
    this.testTimestamp = testTimestamp;
    this.pvtTestDuration = pvtTestDuration;
    this.falseStart = falseStart;
    this.longPressError = longPressError;
    this.sleepAttack = sleepAttack;
    this.practiceRun = practiceRun;
    this.isiExpectedDelay = isiExpectedDelay;
    this.isiActualDelay = isiActualDelay;
    this.isiStartTime = isiStartTime;
    this.isiEndTime = isiEndTime;
  }

  toJSON(result_id) {
    if (this.falseStart || this.longPressError) {
      this.reactionTime = 0;
    }

    return {
      timestamp: new Date(this.timestamp).toISOString(),
      result_id: result_id,
      reaction_time_millis: this.reactionTime,
      false_start: this.falseStart,
      sleep_attack: this.sleepAttack,
      isi_expected_delay: this.isiExpectedDelay,
      isi_actual_delay: this.isiActualDelay,
      isi_start_time: new Date(this.isiStartTime).toISOString(),
      isi_end_time: new Date(this.isiEndTime).toISOString(),
      long_press_error: this.longPressError,
      practice_run: this.practiceRun,
    };
  }
}

export class PVTResult {
  constructor(
    lapseDuration,
    pvtTestDuration,
    testTimestamp,
    completed = false,
  ) {
    this.lapseDuration = lapseDuration;
    this.pvtTestDuration = pvtTestDuration;
    this.testTimestamp = testTimestamp;
    this.reactions = [];
    this.falseStarts = [];
    this.completed = completed;
  }

  setReactions(reactions) {
    console.group("PVTResult.setReactions()");

    this.reactions = reactions;
    this.original_reactions = Array.from(reactions);
    this.falseStarts = [];
    this.longPressErrors = [];

    for (let i = 0; i < this.reactions.length; i++) {
      // Filter out the false starts and long presses.
      let currentReaction = this.reactions[i];
      if (currentReaction.falseStart) {
        console.debug(
          "Removing false start from real reactions with reaction time: " +
            currentReaction.reactionTime,
        );
        this.falseStarts.push(currentReaction);
        this.reactions.splice(i, 1);
        i--;
      } else if (currentReaction.longPressError) {
        console.debug(
          "Removing long press error from real reactions with reaction time: " +
            currentReaction.reactionTime,
        );
        this.longPressErrors.push(currentReaction);
        this.reactions.splice(i, 1);
        i--;
      } else {
        console.debug(
          "Regular reaction time left alone: " + currentReaction.reactionTime,
        );
      }
    }

    this.reactions.sort(function (a, b) {
      return a.reactionTime - b.reactionTime;
    });

    console.debug("False Starts");
    console.debug(this.falseStarts);
    console.debug("Long Presses");
    console.debug(this.longPressErrors);
    console.debug("Reactions");
    console.debug(this.reactions);
    console.groupEnd();
  }

  toJSON() {
    return {
      timestamp: new Date(this.testTimestamp).toISOString(),
      test_duration_minutes: this.pvtTestDuration / 1000 / 60,
      lapse_duration_millis: this.lapseDuration,
      completed: this.completed,
    };
  }

  meanRT() {
    console.group("PVTResult.meanRT()");
    let total = 0;

    for (let i = 0; i < this.reactions.length; i++) {
      console.debug("total += this.reactions[i].reactionTime");
      console.debug(total + " += " + this.reactions[i].reactionTime);
      total += this.reactions[i].reactionTime;
    }

    console.debug("total / this.reactions.length");
    console.debug(total + " / " + this.reactions.length);
    console.groupEnd();
    return total / this.reactions.length;
  }
}

export class DummyPVTResult extends PVTResult {
  constructor() {
    super(355, 30 * 1000, Date.now());
  }

  meanRT() {
    return 254.5;
  }
}
