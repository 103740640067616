import React from "react";

import "../css/nasa_tlx.css";
import { ValidationFailedPopup } from "../validation";

export class NasaTLX extends React.Component {
  constructor(props) {
    super(props);

    this.validateForm = this.validateForm.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onPopupClosed = this.onPopupClosed.bind(this);
    this.onGradationValueChanged = this.onGradationValueChanged.bind(this);

    this.state = {
      result: new NasaTLXResult(),
      hasValidatedOnce: false,
      showingErrorPopup: false,
      errors: {},
    };
  }

  render() {
    if (!this.props.visible) {
      return "";
    }

    return (
      <div className={"modal-overlay"}>
        <div id={"tlx-container"} className={"modal-container"}>
          <p className={"tlx-instructions-section"}>
            Please place an "X" along each scale at the point that best
            indicates your experience with the task.
          </p>
          <div id={"tlx-form-container"}>
            <GradationChoice
              id={"mental-demand"}
              questionTitle={"Mental Demand"}
              questionBody={
                "How much mental and perceptual activity was required " +
                "(e.g. thinking, deciding, calculating, remembering, looking, searching, etc)? " +
                "Was the task easy or demanding, simple or complex, exacting or forgiving?"
              }
              value={this.state.result.mentalDemand}
              onLineClicked={this.onGradationValueChanged}
              leftAxis={"Very Low"}
              rightAxis={"Very High"}
              error={this.state.errors["mentalDemand"]}
            />
            <GradationChoice
              id={"physical-demand"}
              questionTitle={"Physical Demand"}
              questionBody={
                "How much physical activity was required " +
                "(e.g. pushing, pulling, turning, controlling, activating, etc.)?" +
                " Was the task easy or demanding, slow or brisk, slack or strenuous, restful or laborious?"
              }
              value={this.state.result.physicalDemand}
              onLineClicked={this.onGradationValueChanged}
              leftAxis={"Very Low"}
              rightAxis={"Very High"}
              error={this.state.errors["physicalDemand"]}
            />
            <GradationChoice
              id={"temporal-demand"}
              questionTitle={"Temporal Demand"}
              questionBody={
                "How much time pressure did you feel due to the rate or pace at which the task occured? " +
                "Was the pace slow and leisurely or rapid and frantic?"
              }
              value={this.state.result.temporalDemand}
              onLineClicked={this.onGradationValueChanged}
              leftAxis={"Very Low"}
              rightAxis={"Very High"}
              error={this.state.errors["temporalDemand"]}
            />
            <GradationChoice
              id={"performance"}
              questionTitle={"Performance"}
              questionBody={
                "How successful do you think you were in accomplshing the goals of the task? " +
                "How satisfied were you with your performance in accomplishing these goals?"
              }
              value={this.state.result.performance}
              onLineClicked={this.onGradationValueChanged}
              leftAxis={"Perfect"}
              rightAxis={"Failure"}
              error={this.state.errors["performance"]}
            />
            <GradationChoice
              id={"effort"}
              questionTitle={"Effort"}
              questionBody={
                "How hard did you have to work (mentally and physically) to accomplish your level of performance?"
              }
              value={this.state.result.effort}
              onLineClicked={this.onGradationValueChanged}
              leftAxis={"Very Low"}
              rightAxis={"Very High"}
              error={this.state.errors["effort"]}
            />
            <GradationChoice
              id={"frustration"}
              questionTitle={"Frustration"}
              questionBody={
                "How discouraged, stressed, irritated, and annoyed versus " +
                "gratified, relaxed, content, and complacent did you feel during your task?"
              }
              value={this.state.result.frustration}
              onLineClicked={this.onGradationValueChanged}
              leftAxis={"Very Low"}
              rightAxis={"Very High"}
              error={this.state.errors["frustration"]}
            />
            <div id={"nasa-tlx-submit-container"}>
              <button
                className={"button tlx-submit-button"}
                onClick={this.handleSubmit}
              >
                Submit
              </button>
            </div>
          </div>
          <ValidationFailedPopup
            showSubjectIDPrompt={false}
            hasErrors={this.state.showingErrorPopup}
            onPopupClosed={() => {
              this.onPopupClosed();
            }}
          />
        </div>
      </div>
    );
  }

  onGradationValueChanged(gradationId, value) {
    let result = this.state.result;

    if (gradationId === "mental-demand") {
      result.mentalDemand = value;
    } else if (gradationId === "physical-demand") {
      result.physicalDemand = value;
    } else if (gradationId === "temporal-demand") {
      result.temporalDemand = value;
    } else if (gradationId === "performance") {
      result.performance = value;
    } else if (gradationId === "effort") {
      result.effort = value;
    } else if (gradationId === "frustration") {
      result.frustration = value;
    }

    this.setState({
      result: result,
    });
  }

  validateForm() {
    if (this.state.hasValidatedOnce) {
      this.props.onTLXSaved(this.state.result);
      return;
    }

    let newErrors = {
      mentalDemand: this.state.result.mentalDemand === null,
      physicalDemand: this.state.result.physicalDemand === null,
      temporalDemand: this.state.result.temporalDemand === null,
      performance: this.state.result.performance === null,
      effort: this.state.result.effort === null,
      frustration: this.state.result.frustration === null,
    };

    let hasErrors;

    for (const [_, hasError] of Object.entries(newErrors)) {
      if (hasError) {
        hasErrors = true;
        break;
      }
    }

    let validated = this.state.hasValidatedOnce || !hasErrors;

    this.setState({
      hasValidatedOnce: true,
      showingErrorPopup: !validated,
      errors: newErrors,
    });

    return validated;
  }

  handleSubmit() {
    if (!this.validateForm()) {
      return;
    }

    this.props.onTLXSaved(this.state.result);
  }

  onPopupClosed() {
    this.setState({
      showingErrorPopup: false,
    });
  }
}

class NasaTLXResult {
  constructor() {
    this.mentalDemand = null;
    this.physicalDemand = null;
    this.temporalDemand = null;
    this.performance = null;
    this.effort = null;
    this.frustration = null;
  }

  toJSON(test_id) {
    return {
      result_id: test_id,
      mental_demand: this.mentalDemand,
      physical_demand: this.physicalDemand,
      temporal_demand: this.temporalDemand,
      performance: this.performance,
      effort: this.effort,
      frustration: this.frustration,
    };
  }
}

function GradationChoice(props) {
  let lines = [];

  for (let i = 0; i < 21; i++) {
    lines.push(
      <LineContainer
        middleLine={i === 10 || i === 0 || i === 20}
        hasX={props.value === i}
        value={i}
        key={i}
        onLineClicked={() => {
          props.onLineClicked(props.id, i);
        }}
      />,
    );
  }

  return (
    <div
      className={
        props.error ? "gradation-container has-errors" : "gradation-container"
      }
    >
      <div className={"prompt-container"}>
        <div className={"question-title"}>{props.questionTitle}</div>
        <div className={"question-spacer"} />
        <div className={"question-body"}>{props.questionBody}</div>
      </div>
      <div className={"scale-container"}>{lines}</div>
      <div className={"gradation-bottom-border"}></div>
      <div className="axis-container">
        <div className="left-axis axis">{props.leftAxis}</div>
        <div className="right-axis axis">{props.rightAxis}</div>
      </div>
    </div>
  );
}

function LineContainer(props) {
  let containerClassName = "line-container";
  let spanClassName = "";

  if (props.middleLine) {
    containerClassName += " middle-line";
  } else if (props.value % 2 === 0) {
    containerClassName += " high-range";
  }

  if (props.hasX) {
    spanClassName += " has-x";
  }

  return (
    <div className={containerClassName} onClick={props.onLineClicked}>
      <div className={"line"}>
        <span className={spanClassName}>X</span>
      </div>
    </div>
  );
}
