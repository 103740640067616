import { PVTReaction, PVTResult } from "./results";
import { SubjectInfo } from "./subject_data";

const API_KEY = "9463d2d2-8560-40ea-8f4e-739ac9afed2c";

class JSONAPIDriver {
  constructor(debug = false) {
    this.debug = debug;
    this.BASE_URL = window.subdomain ?? "/";
  }

  put(request_url, data = {}) {
    if (this.debug) {
      return Promise.resolve();
    }

    let url = this.BASE_URL + "/" + request_url;

    if (url.startsWith("//")) {
      url = url.substring(1);
    }

    let payload = JSON.stringify(data);
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Length": payload.length,
        "Content-Type": "application/json",
        "X-HTTP-APIKEY": API_KEY,
      },
      body: payload,
    };

    console.debug("Sending request to server: " + url + " - " + payload);
    return fetch(url, requestOptions).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return Promise.reject(response);
      }
    });
  }
}

export class OnlinePVTDriver extends JSONAPIDriver {
  constructor(debug = false) {
    super(debug);
  }

  testDriverPutResult() {
    // Testing the server requests.
    let testTimestamp = Date.now();
    let testPVTDuration = 180;
    let testPVTLapseDuration = 355;

    return this.putPVTResult(
      new PVTResult(
        [
          new PVTReaction(
            201,
            testTimestamp,
            testPVTDuration,
            false,
            false,
            1095,
            1097
          ),
          new PVTReaction(201, testTimestamp, testPVTDuration, false, false),
          new PVTReaction(201, testTimestamp, testPVTDuration, false, false),
          new PVTReaction(201, testTimestamp, testPVTDuration, false, false),
          new PVTReaction(201, testTimestamp, testPVTDuration, false, false),
          new PVTReaction(201, testTimestamp, testPVTDuration, false, false),
        ],
        testPVTLapseDuration,
        testPVTDuration,
        testTimestamp
      ),
      new SubjectInfo(
        "123456",
        7.5,
        9,
        false,
        true,
        new Date(2020, 9, 30, 7),
        8.5
      ),
      (data) => {
        console.log("Successfully uploaded fake results!");
        console.log(data);
      },
      (error) => {
        console.log("Failed to upload fake results!");
        console.log(error);
      }
    );
  }

  putPVTResult(result, subject_info, practiceReactions) {
    if (this.debug) {
      return Promise.resolve(1);
    }
    const driver = this;
    return this.put("api/results", result.toJSON())
      .then((data) => {
        return driver.putSubjectInfo(data[0]["id"], subject_info);
      })
      .then((data) => {
        return driver.putPVTReactions(data[0]["result_id"], practiceReactions);
      })
      .then((data) => {
        return data[0]["result_id"];
      });
  }

  putPVTReactions(result_id, reactions) {
    let json_reactions = [];

    for (let i = 0; i < reactions.length; i++) {
      json_reactions.push(reactions[i].toJSON(result_id));
    }

    return this.put("api/reactions", json_reactions);
  }

  putPVTReaction(result_id, reaction) {
    let json_reactions = [reaction.toJSON(result_id)];
    return this.put("api/reactions", json_reactions);
  }

  putMindWanderingResult(result_id, mindWanderingResult) {
    return this.put(
      "api/mind_wandering",
      mindWanderingResult.toJSON(result_id)
    );
  }

  putTLXResult(result_id, tlxResult) {
    return this.put("api/tlx", tlxResult.toJSON(result_id));
  }

  completePVTResult(result_id) {
    return this.put("api/results/complete?result_id=" + result_id);
  }

  putSubjectInfo(result_id, subject_info) {
    return this.put("api/subject_info", subject_info.toJSON(result_id));
  }
}
