import React from "react";
import { ValidationFailedPopup } from "../validation";

import "../css/mind_wandering.css";

export class MindWanderingPage extends React.Component {
  constructor(props) {
    super(props);

    this.onWorkCarefullyChanged = this.onWorkCarefullyChanged.bind(this);
    this.onTimeLeftChanged = this.onTimeLeftChanged.bind(this);
    this.onOthersChanged = this.onOthersChanged.bind(this);
    this.onDifficultyChanged = this.onDifficultyChanged.bind(this);
    this.onAbilityChanged = this.onAbilityChanged.bind(this);
    this.onPurposeChanged = this.onPurposeChanged.bind(this);
    this.onPerformedChanged = this.onPerformedChanged.bind(this);
    this.onConfusedChanged = this.onConfusedChanged.bind(this);
    this.onFamilyMembersChanged = this.onFamilyMembersChanged.bind(this);
    this.onGuiltyChanged = this.onGuiltyChanged.bind(this);
    this.onWorriesChanged = this.onWorriesChanged.bind(this);
    this.onAngryChanged = this.onAngryChanged.bind(this);
    this.onEarlierChanged = this.onEarlierChanged.bind(this);
    this.onRecentPastChanged = this.onRecentPastChanged.bind(this);
    this.onDistantPastChanged = this.onDistantPastChanged.bind(this);
    this.onFutureChanged = this.onFutureChanged.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      result: new MindWanderingResult(),
      hasValidatedOnce: false,
      showingErrorPopup: false,
      errors: {},
    };
  }

  render() {
    if (!this.props.visible) {
      return "";
    }

    return (
      <div className={"modal-overlay"}>
        <div id={"mind-wandering-container"} className={"modal-container"}>
          <p className={"mind-wandering-instructions-section"}>
            This set of questions concerns the kinds of thoughts that go through
            people's heads at particular times, for example while they are doing
            some task or activity.
          </p>
          <p className={"mind-wandering-instructions-section"}>
            Below is a list of thoughts, some of which you might have had
            recently.
          </p>
          <p className={"mind-wandering-instructions-section"}>
            Please indicate roughly how often you had each thought while
            performing the task, by selecting the most accurate answer. Your
            responses will be kept in absolute confidence.
          </p>
          <hr className={"ratings-legend-separator"} />
          <div id={"ratings-options-legend"}>
            <div className={"rating-legend-option"}>1 = Never</div>
            <div className={"rating-legend-option"}>2 = Once</div>
            <div className={"rating-legend-option"}>3 = A few times</div>
            <div className={"rating-legend-option"}>4 = Often</div>
            <div className={"rating-legend-option"}>5 = Very often</div>
          </div>
          <hr className={"ratings-legend-separator"} />
          <table className={"array-question-table"} id={"mind-wandering-table"}>
            <tbody className={"array-question-table-body"}>
              <MindWanderingQuestion
                question_id={"work_carefully"}
                question_prompt={
                  "I thought about how I should work more carefully."
                }
                question_name={"work_carefully"}
                onValueChanged={this.onWorkCarefullyChanged}
                error={this.state.errors["work_carefully"]}
              />
              <MindWanderingQuestion
                question_id={"time_left"}
                question_prompt={"I thought about how much time I had left."}
                question_name={"time_left"}
                onValueChanged={this.onTimeLeftChanged}
                error={this.state.errors["time_left"]}
              />
              <MindWanderingQuestion
                question_id={"others"}
                question_prompt={
                  "I thought about how others have done on this task."
                }
                question_name={"others"}
                onValueChanged={this.onOthersChanged}
                error={this.state.errors["others"]}
              />
              <MindWanderingQuestion
                question_id={"difficulty"}
                question_prompt={
                  "I thought about the difficulty of the problems."
                }
                question_name={"difficulty"}
                onValueChanged={this.onDifficultyChanged}
                error={this.state.errors["difficulty"]}
              />
              <MindWanderingQuestion
                question_id={"ability"}
                question_prompt={"I thought about my level of ability."}
                question_name={"ability"}
                onValueChanged={this.onAbilityChanged}
                error={this.state.errors["ability"]}
              />
              <MindWanderingQuestion
                question_id={"purpose"}
                question_prompt={
                  "I thought about the purpose of the experiment."
                }
                question_name={"purpose"}
                onValueChanged={this.onPurposeChanged}
                error={this.state.errors["purpose"]}
              />
              <MindWanderingQuestion
                question_id={"performed"}
                question_prompt={
                  "I thought about how I would feel if I were told how I performed."
                }
                question_name={"performed"}
                onValueChanged={this.onPerformedChanged}
                error={this.state.errors["performed"]}
              />
              <MindWanderingQuestion
                question_id={"confused"}
                question_prompt={"I thought about how often I get confused."}
                question_name={"confused"}
                onValueChanged={this.onConfusedChanged}
                error={this.state.errors["confused"]}
              />
              <MindWanderingQuestion
                question_id={"family_members"}
                question_prompt={"I thought about members of my family."}
                question_name={"family_members"}
                onValueChanged={this.onFamilyMembersChanged}
                error={this.state.errors["family_members"]}
              />
              <MindWanderingQuestion
                question_id={"guilty"}
                question_prompt={
                  " I thought about something that made me feel guilty."
                }
                question_name={"guilty"}
                onValueChanged={this.onGuiltyChanged}
                error={this.state.errors["work_carefully"]}
              />
              <MindWanderingQuestion
                question_id={"worries"}
                question_prompt={" I thought about personal worries."}
                question_name={"worries"}
                onValueChanged={this.onWorriesChanged}
                error={this.state.errors["work_carefully"]}
              />
              <MindWanderingQuestion
                question_id={"angry"}
                question_prompt={
                  "I thought about something that made me feel angry."
                }
                question_name={"angry"}
                onValueChanged={this.onAngryChanged}
                error={this.state.errors["angry"]}
              />
              <MindWanderingQuestion
                question_id={"earlier"}
                question_prompt={
                  "I thought about something that happened earlier today."
                }
                question_name={"earlier"}
                onValueChanged={this.onEarlierChanged}
                error={this.state.errors["earlier"]}
              />
              <MindWanderingQuestion
                question_id={"recent_past"}
                question_prompt={
                  "I thought about something that happened in the recent past (last few days, but not today)."
                }
                question_name={"recent_past"}
                onValueChanged={this.onRecentPastChanged}
                error={this.state.errors["recent_past"]}
              />
              <MindWanderingQuestion
                question_id={"distant_past"}
                question_prompt={
                  "I thought about something that happened in the distant past."
                }
                question_name={"distant_past"}
                onValueChanged={this.onDistantPastChanged}
                error={this.state.errors["distant_past"]}
              />
              <MindWanderingQuestion
                question_id={"future"}
                question_prompt={
                  "I thought about something that might happen in the future."
                }
                question_name={"future"}
                onValueChanged={this.onFutureChanged}
                error={this.state.errors["future"]}
              />
            </tbody>
          </table>
          <hr className={"ratings-legend-separator"} />
          <div id={"ratings-options-legend"}>
            <div className={"rating-legend-option"}>1 = Never</div>
            <div className={"rating-legend-option"}>2 = Once</div>
            <div className={"rating-legend-option"}>3 = A few times</div>
            <div className={"rating-legend-option"}>4 = Often</div>
            <div className={"rating-legend-option"}>5 = Very often</div>
          </div>
          <div id={"mind-wandering-submit-container"}>
            <button
              className={"button"}
              id={"mind-wandering-submit-button"}
              onClick={this.handleSubmit}
            >
              Submit
            </button>
          </div>

          <ValidationFailedPopup
            showSubjectIDPrompt={false}
            hasErrors={this.state.showingErrorPopup}
            onPopupClosed={() => {
              this.onPopupClosed();
            }}
          />
        </div>
      </div>
    );
  }

  validateForm() {
    if (this.state.hasValidatedOnce) {
      this.props.onMindWanderingSaved(this.state.result);
      return;
    }

    let newErrors = {
      work_carefully: this.state.result.work_carefully === null,
      time_left: this.state.result.time_left === null,
      others: this.state.result.others === null,
      difficulty: this.state.result.difficulty === null,
      ability: this.state.result.ability === null,
      purpose: this.state.result.purpose === null,
      performance: this.state.result.performance === null,
      confused: this.state.result.confused === null,
      family_members: this.state.result.family_members === null,
      guilty: this.state.result.guilty === null,
      worries: this.state.result.worries === null,
      angry: this.state.result.angry === null,
      earlier: this.state.result.earlier === null,
      recent_past: this.state.result.recent_past === null,
      distant_past: this.state.result.distant_past === null,
      future: this.state.result.future === null,
    };

    let hasErrors;

    for (const [_, hasError] of Object.entries(newErrors)) {
      if (hasError) {
        hasErrors = true;
        break;
      }
    }

    let validated = this.state.hasValidatedOnce || !hasErrors;

    this.setState({
      hasValidatedOnce: true,
      showingErrorPopup: !validated,
      errors: newErrors,
    });

    return validated;
  }

  handleSubmit() {
    if (!this.validateForm()) {
      return;
    }

    this.props.onMindWanderingSaved(this.state.result);
  }

  onPopupClosed() {
    this.setState({
      showingErrorPopup: false,
    });
  }

  onWorkCarefullyChanged(value) {
    let result = this.state.result;
    result.work_carefully = value;

    this.setState({
      result: result,
    });
  }

  onTimeLeftChanged(value) {
    let result = this.state.result;
    result.time_left = value;

    this.setState({
      result: result,
    });
  }

  onOthersChanged(value) {
    let result = this.state.result;
    result.others = value;

    this.setState({
      result: result,
    });
  }

  onDifficultyChanged(value) {
    let result = this.state.result;
    result.difficulty = value;

    this.setState({
      result: result,
    });
  }

  onAbilityChanged(value) {
    let result = this.state.result;
    result.ability = value;

    this.setState({
      result: result,
    });
  }

  onPurposeChanged(value) {
    let result = this.state.result;
    result.purpose = value;

    this.setState({
      result: result,
    });
  }

  onPerformedChanged(value) {
    let result = this.state.result;
    result.performed = value;

    this.setState({
      result: result,
    });
  }

  onConfusedChanged(value) {
    let result = this.state.result;
    result.confused = value;

    this.setState({
      result: result,
    });
  }

  onFamilyMembersChanged(value) {
    let result = this.state.result;
    result.family_members = value;

    this.setState({
      result: result,
    });
  }

  onGuiltyChanged(value) {
    let result = this.state.result;
    result.guilty = value;

    this.setState({
      result: result,
    });
  }

  onWorriesChanged(value) {
    let result = this.state.result;
    result.worries = value;

    this.setState({
      result: result,
    });
  }

  onAngryChanged(value) {
    let result = this.state.result;
    result.angry = value;

    this.setState({
      result: result,
    });
  }

  onEarlierChanged(value) {
    let result = this.state.result;
    result.earlier = value;

    this.setState({
      result: result,
    });
  }

  onRecentPastChanged(value) {
    let result = this.state.result;
    result.recent_past = value;

    this.setState({
      result: result,
    });
  }

  onDistantPastChanged(value) {
    let result = this.state.result;
    result.distant_past = value;

    this.setState({
      result: result,
    });
  }

  onFutureChanged(value) {
    let result = this.state.result;
    result.future = value;

    this.setState({
      result: result,
    });
  }
}

export class MindWanderingResult {
  constructor() {
    this.work_carefully = null;
    this.time_left = null;
    this.others = null;
    this.difficulty = null;
    this.ability = null;
    this.purpose = null;
    this.performed = null;
    this.confused = null;
    this.family_members = null;
    this.guilty = null;
    this.worries = null;
    this.angry = null;
    this.earlier = null;
    this.recent_past = null;
    this.distant_past = null;
    this.future = null;
  }

  toJSON(test_id) {
    return {
      result_id: test_id,
      work_carefully: this.work_carefully,
      time_left: this.time_left,
      others: this.others,
      difficulty: this.difficulty,
      ability: this.ability,
      purpose: this.purpose,
      performed: this.performed,
      confused: this.confused,
      family_members: this.family_members,
      guilty: this.guilty,
      worries: this.worries,
      angry: this.angry,
      earlier: this.earlier,
      recent_past: this.recent_past,
      distant_past: this.distant_past,
      future: this.future,
    };
  }
}

function MindWanderingQuestion(props) {
  return (
    <tr
      className={
        props.error
          ? "array-question-table-row has-errors"
          : "array-question-table-row"
      }
      id={props.question_id}
    >
      <td className={"array-question-prompt-cell array-question-table-cell"}>
        {props.question_prompt}
      </td>
      <td className={"array-question-answer-cell array-question-table-cell"}>
        <RatingScaleList
          answers={props.answers}
          group_name={props.question_name}
          onValueChanged={props.onValueChanged}
        />
      </td>
    </tr>
  );
}

function RatingScaleList(props) {
  let radios = [];

  for (let i = 1; i <= 5; i++) {
    radios.push(
      <label className={"rating-option-container"} key={i}>
        <input
          className={"rating-scale-radio"}
          type={"radio"}
          id={i}
          name={props.group_name}
          value={i}
          onClick={() => {
            props.onValueChanged(i);
          }}
        />
        <p className={"rating-scale-label"}>{i}</p>
      </label>,
    );
  }

  return <div className={"rating-scale-container"}>{radios}</div>;
}
