/**
 * Created by Qisheng Li in 11/2019.
 */

import React from "react";

import card from "../assets/card.png";

import $ from "jquery";
import "jquery-ui/themes/base/core.css";
import "jquery-ui/themes/base/theme.css";
import "jquery-ui/themes/base/slider.css";
import "jquery-ui/ui/widgets/slider";
import { SVG } from "@svgdotjs/svg.js";

// Measured view distance (inches): 15 1/8
// Measured view distance (mm): 384.175
// Trial 1: viewDistanceMM 376.46 pixelsToMM 4.777 ball_sqr_distance 88.7002
// Trial 2: viewDistanceMM 369.19 pixelsToMM 4.777 ball_sqr_distance 86.987
// Trial 3: viewDistanceMM 381.92 pixelsToMM 4.777 ball_sqr_distance 89.9875
// Trial 4: viewDistanceMM 410.86 pixelsToMM 4.777 ball_sqr_distance 97.78

//===================
//Helper Functions
function fullScreen() {
  let doc = document.documentElement;
  // noinspection JSUnresolvedVariable
  if (doc.requestFullScreen) {
    // noinspection JSUnresolvedFunction
    doc.requestFullScreen();
  } else {
    // noinspection JSUnresolvedVariable
    if (doc.mozRequestFullScreen) {
      // noinspection JSUnresolvedFunction
      doc.mozRequestFullScreen();
    } else {
      // noinspection JSUnresolvedFunction,JSUnresolvedVariable
      if (doc.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT)) {
        // noinspection JSUnresolvedVariable,JSUnresolvedFunction
        doc.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    }
  }
}

// Converts from degrees to radians.
Math.radians = function (degrees) {
  return (degrees * Math.PI) / 180;
};

function roundDistance(value, decimals) {
  return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
}

function pixelsToMM(cardImageWidth) {
  const cardWidth = 85.6; //card dimension: 85.60 × 53.98 mm (3.370 × 2.125 in)
  return cardImageWidth / cardWidth;
}

const VirtualChinrestState = {
  WAITING_FULLSCREEN: "waiting_fullscreen",
  CREDIT_CARD_SIZING: "credit_card_sizing",
  WAITING_BLIND_SPOT: "waiting_blind_spot",
  MEASURING_BLIND_SPOT: "measuring_blind_spot",
  FINISHED: "finished",
};

class VirtualChinrest extends React.Component {
  constructor(props) {
    super(props);

    this.drawBall = this.drawBall.bind(this);
    this.animateBall = this.animateBall.bind(this);
    this.recordPosition = this.recordPosition.bind(this);
    this.fullScreenPromptInterface = this.fullScreenPromptInterface.bind(this);
    this.creditCardMeasureInterface =
      this.creditCardMeasureInterface.bind(this);
    this.blindSpotMeasureInterface = this.blindSpotMeasureInterface.bind(this);
    this.finishedResults = this.finishedResults.bind(this);
    this.onFullScreenButtonClicked = this.onFullScreenButtonClicked.bind(this);
    this.onCreditCardSizingConfirmed =
      this.onCreditCardSizingConfirmed.bind(this);
    this.onStartBlindSpotReadingClicked =
      this.onStartBlindSpotReadingClicked.bind(this);

    this.ball = null;
    this.ballAnimation = null;
    this.square = null;
    this.moveX = null;
    this.ballPosition = [];

    this.state = {
      currentState: VirtualChinrestState.CREDIT_CARD_SIZING,
      ballPosition: [],
      fullScreenClicked: false,
      sliderClicked: false,
      cardWidth: -1,
      squarePosition: -1,
      avgBallPos: -1,
      viewDistanceMM: -1,
      blindSpotReadingsRemaining: 5,
    };
  }

  componentDidMount() {
    let component = this;
    console.debug("Enabling slider!");

    $("#slider").slider({ value: "50" });

    $("#slider").on("slide", function (event, ui) {
      let cardWidth = ui.value + "%";
      $("#card").css({ width: cardWidth });
    });

    $("#slider").on("slidechange", function (_, __) {
      component.setState({
        sliderClicked: true,
      });
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.state.currentState === VirtualChinrestState.WAITING_BLIND_SPOT &&
      prevState.currentState !== VirtualChinrestState.WAITING_BLIND_SPOT
    ) {
      this.drawBall();
      console.debug("drawing ball");
    }

    if (
      this.state.currentState === VirtualChinrestState.MEASURING_BLIND_SPOT &&
      prevState.currentState !== VirtualChinrestState.MEASURING_BLIND_SPOT
    ) {
      console.debug("Turning on keydown listener");
      $(document).on("keydown", this.recordPosition);
    }
  }

  //=============================
  //Ball Animation
  drawBall(pos = 180) {
    // pos: define where the fixation square should be.
    let mySVG = SVG().addTo("#svgDiv").size(1000, 200);
    const cardWidthPx = this.state.cardWidth;
    const rectX = pixelsToMM(cardWidthPx) * pos;
    const ballX = rectX * 0.6; // define where the ball is
    // noinspection JSUnresolvedFunction
    this.ball = mySVG.circle(30).move(ballX, 50).fill("#f00");
    // noinspection JSUnresolvedFunction
    this.square = mySVG
      .rect(30, 30)
      .move(Math.min(rectX - 50, 950), 50)
      .fill("white"); //square pos ition
    this.setState({
      squarePosition: roundDistance(this.square.cx(), 2),
      rectX: rectX,
      ballX: ballX,
    });
  }

  animateBall() {
    let component = this;
    this.ballAnimation = this.ball
      .animate(7000)
      .during(function (pos) {
        component.moveX = -pos * component.state.ballX;
        let moveY = 0;
        component.ball.attr({
          transform: "translate(" + component.moveX + "," + moveY + ")",
        });
      })
      .loop(true, false)
      .after(function () {
        component.animateBall();
      });
  }

  recordPosition(event, angle = 13.5) {
    // angle: define horizontal blind spot entry point position in degrees.
    if (event.keyCode === 32) {
      //Press "Space"
      event.stopPropagation();
      let px2mm = pixelsToMM(this.state.cardWidth);
      let thisBallPosition = roundDistance(this.ball.cx() + this.moveX, 2);
      this.ballPosition.push(thisBallPosition);
      let sum = this.ballPosition.reduce((a, b) => a + b, 0);
      let ballPosLen = this.ballPosition.length;
      let avgBallPos = roundDistance(sum / ballPosLen, 2);
      let ball_sqr_distance = (this.state.squarePosition - avgBallPos) / px2mm;
      let viewDistance = ball_sqr_distance / Math.radians(angle);
      console.log(Math.radians(angle));
      let viewDistanceMM = roundDistance(viewDistance, 2);

      console.debug("squarePosition:" + this.state.squarePosition);
      console.debug("px2mm: " + px2mm);
      console.debug("ball_sqr_distance: " + ball_sqr_distance);
      console.debug("This ball position: " + thisBallPosition);
      console.debug("Current sum: " + sum);
      console.debug("Current avgBallPos: " + avgBallPos);
      console.debug("viewDistance: " + viewDistance);
      console.debug("viewDistanceMM: " + viewDistanceMM);

      if (this.state.blindSpotReadingsRemaining > 1) {
        this.setState({
          viewDistanceMM: viewDistanceMM,
          blindSpotReadingsRemaining: this.state.blindSpotReadingsRemaining - 1,
        });
        this.ballAnimation.reset();
      } else {
        this.ballAnimation.active(false);
        this.ballAnimation.finish();
        this.props.onViewDistanceCalculated(viewDistanceMM, px2mm);
        // Turn off our keydown listener so we don't override the index's listener anymore.
        $(document).off("keydown", this.recordPosition);
        this.setState({
          viewDistanceMM: viewDistanceMM,
          currentState: VirtualChinrestState.FINISHED,
          blindSpotReadingsRemaining: 5,
        });
      }
    }
  }

  render() {
    return (
      <div
        className={"modal-overlay"}
        style={!this.props.visible ? { display: "none" } : {}}
      >
        <div className={"modal-container"} id={"virtual-chinrest-container"}>
          <div id="content">
            {this.fullScreenPromptInterface()}
            {this.creditCardMeasureInterface()}
            {this.blindSpotMeasureInterface()}
            {this.finishedResults()}
          </div>
        </div>
      </div>
    );
  }

  fullScreenPromptInterface() {
    if (this.state.currentState !== VirtualChinrestState.WAITING_FULLSCREEN) {
      return "";
    }

    return (
      <h3>
        {" "}
        This experiment must be run in fullscreen mode. <br />
        Please click here to make the page fullscreen.
        <br />
        Remain in fullscreen throughout the experiment.
        <br />
        <br />
        <button
          className={"button"}
          id={"fullscreen-button"}
          onClick={this.onFullScreenButtonClicked}
        >
          Fullscreen mode
        </button>
      </h3>
    );
  }

  creditCardMeasureInterface() {
    if (this.state.currentState !== VirtualChinrestState.CREDIT_CARD_SIZING) {
      return "";
    }

    return (
      <div>
        <h3>Lets determine your screen size</h3>
        <p>
          Please use any credit card that you have available (it can also be a
          grocery store membership card, your drivers license, or anything that
          is of the same format), hold it onto the screen, and adjust the slider
          below to its size.
        </p>

        <p>
          (If you don't have access to a real card, you can use a ruler to
          measure image width to 3.37inch or 85.6mm, or make your best guess!)
        </p>
        <b className={"italic-bold"}>
          Make sure you put the card onto your screen.
        </b>
        <br />

        <div id="container">
          <div id="slider" />
          <br />
          <img id="card" src={card} />
          <br />
          <br />
          <button className="button" onClick={this.onCreditCardSizingConfirmed}>
            Click here when you are done!
          </button>
        </div>
      </div>
    );
  }

  blindSpotMeasureInterface() {
    if (
      this.state.currentState !== VirtualChinrestState.MEASURING_BLIND_SPOT &&
      this.state.currentState !== VirtualChinrestState.WAITING_BLIND_SPOT
    ) {
      return "";
    }

    return (
      <div id="blind-spot">
        <h3>Now, let’s quickly test how far away you are sitting.</h3>
        <p>
          You might know that vision tests at a doctor’s practice often involve
          chinrests; the doctor basically asks you to sit away from a screen in
          a specific distance. We do this here with a “virtual chinrest”.
        </p>

        <h3>Instructions</h3>
        <p>
          1. Put your finger on <b>space bar</b> on the keyboard.
        </p>
        <p>
          2. Close your right eye.{" "}
          <em>(Tip: it might be easier to cover your right eye by hand!)</em>
        </p>
        <p>3. Using your left eye, focus on the white square.</p>
        <p>
          4. Click the button below to start the animation of the red ball. The{" "}
          <b className={"red-text"}>red ball</b> will disappear as it moves from
          right to left. Press the “Space” key as soon as the ball disappears
          from your eye sight.
        </p>
        <br />
        <p>
          Please do it <b>five</b> times. Keep your right eye closed and hit the
          “Space” key fast!
        </p>
        <br />

        <h3>
          Hit 'space'{" "}
          <div id="click" className={"space-click-button"}>
            {this.state.blindSpotReadingsRemaining}
          </div>{" "}
          more times!
        </h3>

        <div id="svgDiv" />
        <br />
        <button
          className="button"
          id="start"
          onClick={this.onStartBlindSpotReadingClicked}
          disabled={
            this.state.currentState !== VirtualChinrestState.WAITING_BLIND_SPOT
          }
        >
          Start
        </button>
      </div>
    );
  }

  finishedResults() {
    if (this.state.currentState !== VirtualChinrestState.FINISHED) {
      return "";
    }

    return (
      <div id="info">
        <h3 id="info-h">
          Estimated viewing distance (cm): {this.state.viewDistanceMM / 10}
        </h3>
        <p id="info-p">
          View more output data in the Console in your browser's
          developer/inspector view.
        </p>
      </div>
    );
  }

  onFullScreenButtonClicked() {
    fullScreen();

    this.setState({
      fullScreenClicked: true,
      currentState: VirtualChinrestState.CREDIT_CARD_SIZING,
    });
  }

  onCreditCardSizingConfirmed() {
    this.setState({
      cardWidth: roundDistance($("#card").width(), 2),
      currentState: VirtualChinrestState.WAITING_BLIND_SPOT,
      blindSpotReadingsRemaining: 5,
    });
  }

  onStartBlindSpotReadingClicked() {
    this.ballPosition = [];
    // animateBall();
    this.setState({
      currentState: VirtualChinrestState.MEASURING_BLIND_SPOT,
    });
    this.animateBall();
  }
}

export default VirtualChinrest;
