import React from "react";
import pvtScreenshot from "./assets/pvt_test_screenshot.png";

export default function InstructionsPage(props) {
  if (props.visible) {
    console.debug("Rendering instructions page");
    if (props.section === 1) {
      console.debug("rendering instructions section 1");
      return (
        <div className="container-fluid" id="instructionsContainer">
          <div id={"instructionsModalWrapper"}>
            <div id="nestedInstructionsContainer">
              <div className={"instructionsTitleContainer"}>
                <h1>INSTRUCTIONS</h1>
              </div>
              <p className={"instructionsTextParagraph"}>
                Before you begin, make sure you are in a location that will not
                distract you and you will be able to fully concentrate on the
                task.
                <br />
                Make sure you are sitting comfortably and can provide key board
                responses in a comfortable manner throughout the task.
                <br />
                Additionally, please close unnecessary programs on your computer
                and sit the same distance away from the computer throughout the
                experiment. <br />
                Please do not drink, eat, talk, listen to music, or perform any
                other distracting activities while completing this study.
                <br />
                Please press the SPACEBAR once you understand the instructions.
              </p>
            </div>
          </div>
        </div>
      );
    } else {
      console.debug("rendering instructions section 2");
      return (
        <div className="container-fluid" id="instructionsContainer">
          <div id={"instructionsModalWrapper"}>
            <div id="nestedInstructionsContainer">
              <div className={"instructionsTitleContainer"}>
                <h1>INSTRUCTIONS</h1>
              </div>
              <p className={"instructionsTextParagraph"}>
                Your task is to press the SPACEBAR as soon as you see a number
                (like the one below) appear in the middle of the screen.
                <br />
                The number will change each time (it signifies the time in
                milliseconds since the last number appeared).
              </p>
              <div id="pvtScreenshotContainer">
                <img
                  id="pvtScreenshot"
                  src={pvtScreenshot}
                  alt="Screenshot of the PVT test running"
                />
              </div>
              <p className={"instructionsTextParagraph"}>
                This is a timed task so please respond as quickly as you can.
                The number will disappear once you respond.
                <br />
                The entire study should take about 40 minutes.
                <br />
                Please press the SPACEBAR once you understand the instructions.
                <br />
              </p>
            </div>
          </div>
        </div>
      );
    }
  } else {
    return "";
  }
}
