import React from "react";
import Select from "react-select";

/**
 * Timezone select dropdown component.
 */
export default class TimezoneSelect extends React.Component {
  constructor(properties) {
    super(properties);

    this.availableTimezones = [
      { label: "Eastern Time, New York City", value: "America/New_York" },
      { label: "Central Time, Chicago", value: "America/Chicago" },
      { label: "Mountain Time, Phoenix", value: "America/Phoenix" },
      {
        label: "Pacific Time, Los Angeles",
        value: "America/Los_Angeles",
      },
      { label: "Alaska Time, Anchorage", value: "America/Anchorage" },
      {
        label: "Hawaii-Aleutian Time, Honolulu",
        value: "Pacific/Hawaii",
      },
      { label: "Other", value: "Other" },
    ];
  }

  render() {
    return (
      <Select
        options={this.availableTimezones}
        onChange={this.props.onTimezoneChanged}
        defaultValue={this.props.currentTimezone}
      />
    );
  }
}
