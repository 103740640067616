import React from "react";
import { render } from "react-dom";
import InstructionsPage from "./instructions";

import "./css/style.css";
import "./css/settings.css";
import "./css/instructions.css";
import "./css/results.css";
import "./css/modal.css";
import "./css/subject_data.css";
import "./css/virtual_chinrest.css";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle";

import "./jquery-timepicker/jquery-timepicker";
import "./jquery-timepicker/jquery-timepicker.css";

import PVTTestState from "./test_states";
import PVTResultsPopup, {
  DummyPVTResult,
  PVTReaction,
  PVTResult,
} from "./results";
import TestHeader, { PracticePrompt } from "./test_headers";
import { OnlinePVTDriver } from "./network";
import SubjectInfoPage from "./subject_data";
import { NasaTLX } from "./post-survey/nasa_tlx";
import { MindWanderingPage } from "./post-survey/mind_wandering";
import VirtualChinrest from "./virtual_chinrest/virtual_chinrest";
import IntermissionMessagePopup from "./post-survey/intermission_message";

Math.radians = function (degrees) {
  return (degrees * Math.PI) / 180;
};

//===================
//Helper Functions
function fullScreen() {
  let doc = document.documentElement;
  // noinspection JSUnresolvedVariable
  if (doc.requestFullScreen) {
    // noinspection JSUnresolvedFunction
    doc.requestFullScreen();
  } else {
    // noinspection JSUnresolvedVariable
    if (doc.mozRequestFullScreen) {
      // noinspection JSUnresolvedFunction
      doc.mozRequestFullScreen();
    } else {
      // noinspection JSUnresolvedFunction,JSUnresolvedVariable
      if (doc.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT)) {
        // noinspection JSUnresolvedVariable,JSUnresolvedFunction
        doc.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    }
  }
}

class PVTTest extends React.Component {
  constructor(props) {
    super(props);

    this.onIntermissionContinued = this.onIntermissionContinued.bind(this);
    this.onMindWanderingSaved = this.onMindWanderingSaved.bind(this);
    this.onTLXQuestionnaireSubmitted =
      this.onTLXQuestionnaireSubmitted.bind(this);
    this.onViewDistanceCalculated = this.onViewDistanceCalculated.bind(this);

    this.serverDriver = new OnlinePVTDriver(
      process.env.NODE_ENV !== "production"
    );
    // this.serverDriver.testDriverPutResult();

    let config = props.config;
    let isiHigh; // The high value is defined as the highest additional delay that can be added when deciding what the ISI
    // will be. In other words, the ISI will be chosen via this formula: (randomDecimal[0->1] * isiHigh) + isiLow;
    let isiLow; // The low value is defined as the lowest value delay that can be added.
    let lapseDuration;

    if (config === undefined || config === null) {
      config = {
        duration: 30,
        compensationCode: "",
      };
    } else {
      config["duration"] = parseInt(config["duration"]);

      if (config["compensationCode"] == null) {
        config["compensationCode"] = "";
      }
    }

    if (config["duration"] <= 300) {
      // < 5 minute duration
      // 2-5 seconds
      isiHigh = 3000;
      isiLow = 2000;
    } else {
      // Default ISI intervals
      // 2-10 seconds
      isiHigh = 8000;
      isiLow = 2000;
    }

    if (config["duration"] <= 180) {
      lapseDuration = 355;
    } else {
      lapseDuration = 500;
    }

    console.debug("Lapse Duration: " + lapseDuration + "ms");

    this.timerID = 0;
    this.pvtTimer = React.createRef();
    this.debugTLX = false;
    this.debugMindWandering = false;
    this.debugResults = false;
    this.debugMain = false;
    this.debugVirtualChinrest = false;

    this.state = {
      result_id: 0,
      viewDistanceMM: -1,
      pixelsToMM: -1,
      stimulusWidthPixels: 100,
      stimulusHeightPixels: 45,
      subjectInfo: null,
      mindWanderingResult: null,
      tlxResult: null,
      firstReaction: true,
      sleepAttack: false,
      pvtTestDuration: config["duration"] * 1000,
      compensationCode: config["compensationCode"],
      lapseDuration: lapseDuration,
      testStartTime: null,
      currentTestState:
        this.debugMain == false
          ? PVTTestState.TEST_SUBJECT_INFO
          : PVTTestState.TEST_STOPPED,
      practiceReactions: [],
      practiceRun: false,
      practiceRuns: 5,
      currentPracticeRun: 1,
      reactions: [],
      testResult: null,
      isiHigh: isiHigh,
      isiLow: isiLow,
      isiDelayRunning: false,
      isiExpectedDelay: null,
      isiActualDelay: null,
      isiStartTime: null,
      isiEndTime: null,
      uploadingTestResults: false,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.state.currentTestState === PVTTestState.TEST_DELAY &&
      !this.state.isiDelayRunning
    ) {
      this.startISIDelay();
    } else if (
      this.state.currentTestState === PVTTestState.TEST_POST_PRACTICE
    ) {
      console.debug(
        "Post practice detected, clearing pvt timer value, please work"
      );
    } else if (this.state.currentTestState === PVTTestState.TEST_REACTION) {
      if (!this.pvtTimer.current.state.timerVisible) {
        // The reaction state was just entered, start a 30s timer so that if they don't react within that window the reaction
        // will automatically track and start over. It will be flagged as a sleep_attack and the RT will be 30s.
        this.startTimer(() => {
          this.onSleepAttackTriggered();
        }, 30000);
      }
      this.pvtTimer.current.enableTimer();
    } else if (
      this.state.currentTestState === PVTTestState.TEST_RESULTS_UPLOAD
    ) {
      this.state.testResult.setReactions(this.state.reactions);
      let app = this;
      this.serverDriver
        .putMindWanderingResult(
          this.state.result_id,
          this.state.mindWanderingResult
        )
        .then((data) => {
          this.serverDriver
            .putTLXResult(this.state.result_id, this.state.tlxResult)
            .then((data) => {
              console.debug("Successfully uploaded TLX Result to server.");
              this.serverDriver
                .completePVTResult(this.state.result_id)
                .then((data) => {
                  console.debug(
                    "Successfully marked our PVT result as completed."
                  );
                  app.setState({
                    currentTestState: PVTTestState.TEST_RESULTS,
                  });
                });
            });
        });
    }

    console.debug(
      "State updated, prev state: " +
        prevState.currentTestState +
        ", current state: " +
        this.state.currentTestState
    );
  }

  componentDidMount() {
    window.addEventListener("keydown", (event) => {
      this.onKeyboardPressed(event);
    });
  }

  componentWillUnmount() {
    clearTimeout(this.timerID);
    window.removeEventListener("keydown", (event) => {
      this.onKeyboardPressed(event);
    });
  }

  render() {
    if (this.debugResults) {
      return (
        <div
          className={"container-fluid"}
          id="testContainer"
          onKeyDown={(event) => this.onKeyboardPressed(event)}
        >
          <PVTResultsPopup
            visible={true}
            result={new DummyPVTResult()}
            compensationCode={10000}
          />
        </div>
      );
    }

    if (this.debugTLX) {
      return (
        <div
          className={"container-fluid"}
          id="testContainer"
          onKeyDown={(event) => this.onKeyboardPressed(event)}
        >
          <NasaTLX
            visible={true}
            onTLXSaved={this.onTLXQuestionnaireSubmitted}
          />
        </div>
      );
    }

    if (this.debugMindWandering) {
      return (
        <div
          className={"container-fluid"}
          id="testContainer"
          onKeyDown={(event) => this.onKeyboardPressed(event)}
        >
          <MindWanderingPage
            visible={true}
            onMindWanderingSaved={this.onMindWanderingSaved}
          />
        </div>
      );
    }

    if (this.debugVirtualChinrest) {
      return (
        <div
          className={"container-fluid"}
          id={"testContainer"}
          onKeyDown={(event) => this.onKeyboardPressed(event)}
        >
          <VirtualChinrest
            onViewDistanceCalculated={this.onViewDistanceCalculated}
            visible={true}
          />
        </div>
      );
    }

    return (
      <div
        className={"container-fluid"}
        id="testContainer"
        onKeyDown={(event) => this.onKeyboardPressed(event)}
      >
        <TestHeader
          practiceStartVisible={
            this.state.currentTestState === PVTTestState.TEST_STOPPED
          }
          trialStartVisible={
            this.state.currentTestState === PVTTestState.TEST_POST_PRACTICE
          }
          practiceRunVisible={this.state.practiceRun}
          practiceAverage={this.practiceAverage()}
          uploadingTestResults={this.state.uploadingTestResults}
        />

        <PracticePrompt
          currentState={this.state.currentTestState}
          practiceRun={this.state.practiceRun}
        />

        <div id="mainTestContainer">
          <PVTTimer
            ref={this.pvtTimer}
            boxVisible={this.shouldShowTimerBox()}
            width={this.state.stimulusWidthPixels}
            height={this.state.stimulusHeightPixels}
          />
        </div>

        <VirtualChinrest
          onViewDistanceCalculated={this.onViewDistanceCalculated}
          visible={
            this.state.currentTestState === PVTTestState.TEST_VIRTUAL_CHINREST
          }
        />

        <InstructionsPage
          visible={
            this.state.currentTestState === PVTTestState.TEST_INSTRUCTIONS1 ||
            this.state.currentTestState === PVTTestState.TEST_INSTRUCTIONS2
          }
          pvtTestDuration={this.state.pvtTestDuration}
          section={
            this.state.currentTestState === PVTTestState.TEST_INSTRUCTIONS1
              ? 1
              : 2
          }
        />

        <PVTResultsPopup
          visible={this.state.currentTestState === PVTTestState.TEST_RESULTS}
          result={this.state.testResult}
          compensationCode={this.state.subjectInfo?.compensationCode}
        />

        <SubjectInfoPage
          visible={
            this.state.currentTestState === PVTTestState.TEST_SUBJECT_INFO
          }
          onSubjectInfoGathered={(subjectInfo) => {
            this.onSubjectInfoGathered(subjectInfo);
          }}
          compensationCode={this.state.compensationCode}
        />

        <IntermissionMessagePopup
          visible={
            this.state.currentTestState === PVTTestState.TEST_INTERMISSION
          }
          onContinueClicked={this.onIntermissionContinued}
        />

        <MindWanderingPage
          visible={
            this.state.currentTestState ===
            PVTTestState.TEST_MIND_WANDERING_QUESTIONNAIRE
          }
          onMindWanderingSaved={this.onMindWanderingSaved}
        />
        <NasaTLX
          visible={
            this.state.currentTestState === PVTTestState.TEST_TLX_QUESTIONNAIRE
          }
          onTLXSaved={this.onTLXQuestionnaireSubmitted}
        />
      </div>
    );
  }

  onIntermissionContinued() {
    this.setState({
      currentTestState: PVTTestState.TEST_MIND_WANDERING_QUESTIONNAIRE,
    });
  }

  onViewDistanceCalculated(viewDistanceMM, pixelsToMM) {
    let newSubjectInfo = this.state.subjectInfo;

    // These constants were calculated using the spreadsheet ./docs/Visual Angle Calculator.xls
    // Values for the spreadsheet were provided from the original MATLAB Experiment ran at UDRI and are as follows:
    // Screen Resolution:
    // 1280 X 800
    // Screen Display Dimensions:
    // 641.28 X 400.8mm (https://www.bhphotovideo.com/c/product/816380-REG/Dell_468_8513_UltraSharp_U3011_30_Monitor.html/specs)
    // Stimulus Size:
    // 100X45 pixels
    // Which results in a target viewing angle of:
    // 4.78142077 degrees horizontal
    // 2.15263566 degrees vertical
    // After that, the formulas on that spreadsheet which allow you to target a specific viewing angle are used
    // along with all the information gathered from the virtual chinrest to determine the stimulus' width and height in pixels.
    let targetHorizontalAngle = 4.78142077;
    let targetVerticalAngle = 2.15263566;

    let screenWidthMM =
      newSubjectInfo.screenWidthPixels / newSubjectInfo.pixelsToMM;
    let screenHeightMM =
      newSubjectInfo.screenHeightPixels / newSubjectInfo.pixelsToMM;

    let stimulusWidth =
      Math.tan(Math.radians(targetHorizontalAngle / 2)) *
      2 *
      pixelsToMM *
      viewDistanceMM;
    let stimulusHeight =
      Math.tan(Math.radians(targetVerticalAngle / 2)) *
      2 *
      pixelsToMM *
      viewDistanceMM;

    newSubjectInfo.viewDistanceMM = viewDistanceMM;
    newSubjectInfo.pixelsToMM = pixelsToMM;
    newSubjectInfo.screenWidthPixels = document.body.clientWidth;
    newSubjectInfo.screenHeightPixels = document.body.clientHeight;
    newSubjectInfo.stimulusWidthPixels = stimulusWidth;
    newSubjectInfo.stimulusHeightPixels = stimulusHeight;

    console.debug(
      "View distance calculated to: " + newSubjectInfo.viewDistanceMM
    );
    console.debug("Pixels To MM: " + newSubjectInfo.pixelsToMM);
    console.debug("Screen Width Pixels: " + newSubjectInfo.screenWidthPixels);
    console.debug("Screen Height Pixels: " + newSubjectInfo.screenHeightPixels);
    console.debug("Screen Width MM: " + screenWidthMM);
    console.debug("Screen Height MM: " + screenHeightMM);
    console.debug("Stimulus Width: " + stimulusWidth);
    console.debug("Stimulus Height: " + stimulusHeight);

    this.setState({
      currentTestState: PVTTestState.TEST_INSTRUCTIONS2,
      subjectInfo: newSubjectInfo,
      stimulusWidthPixels: stimulusWidth,
      stimulusHeightPixels: stimulusHeight,
    });
  }

  onMindWanderingSaved(mindWanderingResult) {
    this.setState({
      currentTestState: PVTTestState.TEST_TLX_QUESTIONNAIRE,
      mindWanderingResult: mindWanderingResult,
    });
  }

  onTLXQuestionnaireSubmitted(tlxResult) {
    this.setState({
      currentTestState: PVTTestState.TEST_RESULTS_UPLOAD,
      tlxResult: tlxResult,
    });
  }

  onSubjectInfoGathered(subjectInfo) {
    console.debug("subject info gathered", subjectInfo);
    this.setState(
      {
        currentTestState: PVTTestState.TEST_INSTRUCTIONS1,
        subjectInfo: subjectInfo,
      },
      () => {
        console.debug("subject info state updated", this.state);
        console.debug(this.state.subjectInfo?.compensationCode);
      }
    );
  }

  practiceRunComplete() {
    return this.state.currentPracticeRun >= this.state.practiceRuns;
  }

  practiceAverage() {
    if (this.state.practiceReactions.length <= 0) {
      return 0;
    }

    let totalNumber = 0;
    let sum = 0;

    for (let i = 0; i < this.state.practiceReactions.length; i++) {
      if (!this.state.practiceReactions[i].falseStart) {
        sum += this.state.practiceReactions[i].reactionTime;
        totalNumber++;
      } else {
      }
    }

    return sum / totalNumber;
  }

  shouldShowTimerBox() {
    //     TEST_STOPPED: "test_stopped",
    //     TEST_DELAY: "test_delay",
    //     TEST_REACTION: "test_reaction",
    //     // This state signifies the 1 second pause where the timer is still displayed but the reaction has been measured already.
    //     TEST_POST_REACTION: "test_post_reaction",
    //     TEST_FALSE_START: "test_false_start",
    return (
      this.state.currentTestState === PVTTestState.TEST_REACTION ||
      this.state.currentTestState === PVTTestState.TEST_POST_REACTION ||
      this.state.currentTestState === PVTTestState.TEST_STOPPED ||
      this.state.currentTestState === PVTTestState.TEST_DELAY ||
      this.state.currentTestState === PVTTestState.TEST_FALSE_START
    );
  }

  getISIDelay() {
    if (this.state.firstReaction) {
      return Math.floor(Math.random() * this.state.isiHigh) + this.state.isiLow;
    } else {
      // If we aren't on the first reaction, remove 1 second from the lowest possible value.
      // i.e. 2-10 seconds becomes 1-9 seconds because there is an implied 1 second RT feedback interval.
      return (
        Math.floor(Math.random() * this.state.isiHigh) +
        (this.state.isiLow - 1000)
      );
    }
  }

  onKeyboardPressed(event) {
    if (event.keyCode === 32) {
      if (this.state.currentTestState === PVTTestState.TEST_REACTION) {
        this.onReactionMeasured();
      } else if (this.state.currentTestState === PVTTestState.TEST_DELAY) {
        this.onReactionMeasured("False Start");
      } else if (
        this.state.currentTestState === PVTTestState.TEST_INSTRUCTIONS1
      ) {
        fullScreen();
        this.setState({
          currentTestState: PVTTestState.TEST_VIRTUAL_CHINREST,
        });
      } else if (
        this.state.currentTestState === PVTTestState.TEST_INSTRUCTIONS2
      ) {
        this.setState({
          currentTestState: PVTTestState.TEST_STOPPED,
        });
      } else if (this.state.currentTestState === PVTTestState.TEST_STOPPED) {
        this.setState({
          currentTestState: PVTTestState.TEST_DELAY,
          practiceRun: true,
          currentPracticeRun: 1,
        });
      } else if (
        this.state.currentTestState === PVTTestState.TEST_POST_PRACTICE
      ) {
        console.log(
          "Starting real test after practice run, uploading initial PVTResult and SubjectInfo data"
        );
        let startTime = Date.now();

        if (this.state.uploadingTestResults) {
          console.debug(
            "Got extra spacebar presses during start of real experiment."
          );
          return;
        }

        this.setState({
          uploadingTestResults: true,
        });

        this.serverDriver
          .putPVTResult(
            new PVTResult(
              this.state.lapseDuration,
              this.state.pvtTestDuration,
              startTime
            ),
            this.state.subjectInfo,
            this.state.practiceReactions
          )
          .then((result_id) => {
            console.log(
              "Finished uploading PVTResult and SubjectInfo, result id: " +
                result_id
            );
            this.setState({
              uploadingTestResults: false,
              result_id: result_id,
              practiceRun: false,
              currentPracticeRun: 1,
              currentTestState: PVTTestState.TEST_DELAY,
              testStartTime: startTime,
            });
          });
      }
    }
  }

  testCompleted() {
    return (
      !this.state.practiceRun &&
      Date.now() - this.state.testStartTime >= this.state.pvtTestDuration
    );
  }

  startISIDelay() {
    console.debug("starting isi delay");
    this.pvtTimer.current.hideTimer();

    let isiExpectedDelay = this.getISIDelay();
    let isiStartTime = Date.now();

    this.startTimer(() => {
      this.onISIDelayFinished();
    }, isiExpectedDelay);
    this.setState({
      isiDelayRunning: true,
      isiExpectedDelay: isiExpectedDelay,
      isiStartTime: isiStartTime,
    });
  }

  onISIDelayFinished() {
    let isiEndTime = Date.now();
    let isiActualDelay = isiEndTime - this.state.isiStartTime;

    this.setState({
      isiDelayRunning: false,
      currentTestState: PVTTestState.TEST_REACTION,
      isiEndTime: isiEndTime,
      isiActualDelay: isiActualDelay,
    });
  }

  onReactionMeasured(valueOverride = null, sleepAttack = false) {
    if (valueOverride !== null) {
      this.pvtTimer.current.stopTimer(valueOverride);
    } else {
      this.pvtTimer.current.stopTimer();
    }

    this.startTimer(() => {
      this.setState({
        currentTestState: PVTTestState.TEST_DELAY,
        isiDelayRunning: false,
      });
    }, 1000);
    this.recordReaction(sleepAttack);
  }

  recordReaction(sleepAttack = false) {
    console.debug(
      "[RECORD_REACTION] - Reaction time was: " +
        this.pvtTimer.current.state.currentTimerValue
    );

    if (this.state.practiceRun) {
      const falseStart =
        this.pvtTimer.current.state.currentTimerValue === "False Start";

      if (this.practiceRunComplete()) {
        this.stopTimer();
        this.startTimer(() => {
          this.setState((state, _) => {
            let practiceReactions;
            let reaction;
            let isiActualDelay = state.isiActualDelay;
            let isiEndTime = state.isiEndTime;

            if (!falseStart) {
              reaction = new PVTReaction(
                this.pvtTimer.current.state.currentTimerValue,
                0,
                this.state.pvtTestDuration,
                false,
                sleepAttack,
                this.state.practiceRun,
                this.state.isiExpectedDelay,
                this.state.isiActualDelay,
                this.state.isiStartTime,
                this.state.isiEndTime
              );
              practiceReactions = state.practiceReactions.concat(reaction);
            } else {
              console.debug("[RECORD_REACTION] - Recording a false start");
              isiEndTime = Date.now();
              isiActualDelay = isiEndTime - state.isiStartTime;
              console.debug(
                `[RECORD_REACTION][PRACTICE][FALSE_START] - Setting false start isi end time to ${isiEndTime}`
              );
              reaction = new PVTReaction(
                0,
                0,
                this.state.pvtTestDuration,
                true,
                sleepAttack,
                this.state.practiceRun,
                this.state.isiExpectedDelay,
                isiActualDelay,
                this.state.isiStartTime,
                isiEndTime
              );
              practiceReactions = state.practiceReactions.concat(reaction);
            }

            return {
              practiceReactions: practiceReactions,
              practiceRun: false,
              currentPracticeRun: 1,
              currentTestState: PVTTestState.TEST_POST_PRACTICE,
              isiActualDelay: isiActualDelay,
              isiEndTime: isiEndTime,
              isiDelayRunning: false,
            };
          });
        }, 1000);
      } else {
        this.setState((state, _) => {
          let practiceReactions;
          let reaction;
          let isiActualDelay = state.isiActualDelay;
          let isiEndTime = state.isiEndTime;

          if (!falseStart) {
            reaction = new PVTReaction(
              this.pvtTimer.current.state.currentTimerValue,
              0,
              this.state.pvtTestDuration,
              false,
              sleepAttack,
              this.state.practiceRun,
              this.state.isiExpectedDelay,
              this.state.isiActualDelay,
              this.state.isiStartTime,
              this.state.isiEndTime
            );
            practiceReactions = state.practiceReactions.concat(reaction);
          } else {
            console.debug("[RECORD_REACTION] - Recording a false start");
            isiEndTime = Date.now();
            isiActualDelay = isiEndTime - state.isiStartTime;
            console.debug(
              `[RECORD_REACTION][FALSE_START] - Setting false start isi end time to ${isiEndTime}`
            );
            reaction = new PVTReaction(
              0,
              0,
              this.state.pvtTestDuration,
              true,
              sleepAttack,
              this.state.practiceRun,
              this.state.isiExpectedDelay,
              isiActualDelay,
              this.state.isiStartTime,
              isiEndTime
            );
            practiceReactions = state.practiceReactions.concat(reaction);
          }

          return {
            firstReaction: false,
            currentTestState: PVTTestState.TEST_POST_REACTION,
            practiceReactions: practiceReactions,
            currentPracticeRun: this.state.currentPracticeRun + 1,
            isiActualDelay: isiActualDelay,
            isiEndTime: isiEndTime,
            isiDelayRunning: false,
          };
        });
      }
      return false;
    }

    this.setState((state, _) => {
      const reactions = this.updateReactions(state, sleepAttack);
      if (this.testCompleted()) {
        this.stopTimer();
        return {
          firstReaction: false,
          currentTestState: PVTTestState.TEST_INTERMISSION,
          reactions: reactions,
          testResult: new PVTResult(
            this.state.lapseDuration,
            this.state.pvtTestDuration,
            this.state.testStartTime
          ),
        };
      } else {
        return {
          reactions: reactions,
          firstReaction: false,
          currentTestState: PVTTestState.TEST_POST_REACTION,
          isiDelayRunning: false,
        };
      }
    });
  }

  updateReactions(state, sleepAttack = false) {
    let isiActualDelay = state.isiActualDelay;
    let isiEndTime = state.isiEndTime;
    const falseStart =
      this.pvtTimer.current.state.currentTimerValue === "False Start";

    if (falseStart) {
      isiEndTime = Date.now();
      isiActualDelay = isiEndTime - state.isiStartTime;
    }

    const reactionTime = this.pvtTimer.current.state.currentTimerValue;
    const testTimestamp = this.pvtTimer.current.state.timerStartTime;
    let reaction = new PVTReaction(
      reactionTime,
      testTimestamp,
      this.state.pvtTestDuration,
      falseStart,
      sleepAttack,
      this.state.practiceRun,
      this.state.isiExpectedDelay,
      isiActualDelay,
      this.state.isiStartTime,
      isiEndTime
    );
    this.serverDriver.putPVTReaction(this.state.result_id, reaction);
    return state.reactions.concat(reaction);
  }

  onSleepAttackTriggered() {
    this.onReactionMeasured(30000, true);
  }

  stopTimer() {
    console.debug("[PVT_TEST_MAIN] - Stopping isi timer");
    clearTimeout(this.timerID);
  }

  startTimer(timerHandler, delay) {
    this.stopTimer();
    console.debug("[PVT_TEST_MAIN] - Starting isi timer");
    this.timerID = setTimeout(timerHandler, delay);
  }
}

class PVTTimer extends React.Component {
  constructor(props) {
    super(props);

    this.timerID = 0;
    this.state = {
      timerVisible: props.visible,
      timerRunning: false,
      currentTimerValue: 0,
      timerStartTime: null,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.timerRunning) {
      this._startTimer(() => {
        this.updateTimer();
      }, 25);
    } else {
      clearTimeout(this.timerID);
    }
  }

  render() {
    return (
      <div
        id={"startTestButtonContainer"}
        style={
          this.props.boxVisible
            ? {
                width: this.props.width,
                height: this.props.height,
              }
            : { display: "none" }
        }
      >
        <div
          id={"pvtTimerContainer"}
          style={this.state.timerVisible ? {} : { display: "none" }}
        >
          <div id={"pvtTimerInner"}>{this.state.currentTimerValue}</div>
        </div>
      </div>
    );
  }

  hideTimer() {
    console.debug("hiding timer");
    this.setState({
      timerVisible: false,
    });
  }

  clearTimerValue() {
    console.debug("removing timer override value");
    this.setState({
      currentTimerValue: null,
    });
  }

  stopTimer(timerValueOverride = null) {
    clearTimeout(this.timerID);

    if (timerValueOverride != null) {
      console.debug("stopping timer with value override");
      this.setState({
        timerVisible: true,
        timerRunning: false,
        currentTimerValue: timerValueOverride,
      });
    } else {
      console.debug("stopping timer without override");
      this.setState({
        timerRunning: false,
      });
    }
  }

  enableTimer() {
    console.debug("enabling pvt timer now");
    this.setState({
      timerVisible: true,
      timerRunning: true,
      currentTimerValue: 0,
      timerStartTime: null,
    });
  }

  updateTimer() {
    let timerStartTime = this.state.timerStartTime;

    if (timerStartTime == null) {
      timerStartTime = Date.now();
      this.setState({
        timerVisible: true,
        timerStartTime: timerStartTime,
        currentTimerValue: 0,
      });
    } else {
      this.setState({
        timerVisible: true,
        currentTimerValue: Date.now() - timerStartTime,
      });
    }
  }

  _startTimer(timerHandler, delay) {
    clearTimeout(this.timerID);
    this.timerID = setTimeout(timerHandler, delay);
  }
}

window.subdomain = "/";

const params = new URLSearchParams(window.location.search);

const pvtConfig = {
  duration: params.get("duration") ?? "180",
  compensationCode: "A6A830661B",
};

console.debug("parsing query params", params);
console.debug(pvtConfig);

render(<PVTTest config={pvtConfig} />, document.getElementById("root"));
