import React from "react";
import $ from "jquery";
import HourPicker from "./hour_picker";
import TimePicker from "./time_picker";
import "datejs/index";
import { ValidationFailedPopup } from "./validation";
import TimezoneSelect from "./timezone_select";

export default class SubjectInfoPage extends React.Component {
  constructor(props) {
    super(props);

    this.timeWokeUp = React.createRef();
    this.onTimezoneChanged = this.onTimezoneChanged.bind(this);

    this.state = {
      hasValidatedOnce: false,
      hasErrors: false,
      validated: false,
      visible: props.visible,
      subjectID: "",
      hoursSlept: "",
      caffeineToday: "",
      usuallyDrinkCaffeine: "",
      timeWokeUp: "",
      usualTimeInBed: "",
      gamingKeyboard: "",
      errors: {},
      showingErrorPopup: false,
      currentTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    console.debug("SubjectInfoPage", this.state);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    $(this.timeWokeUp.current).timepicker({
      interval: 15,
      timeFormat: "h:mm p",
      defaultTime: this.state.timeWokeUp,
      dynamic: true,
      change: (time) => {
        this.onTimeWokeUpChanged(time);
      },
    });
  }

  componentDidMount() {
    $(this.timeWokeUp.current).timepicker({
      interval: 15,
      timeFormat: "h:mm p",
      defaultTime: this.state.timeWokeUp,
      dynamic: true,
      change: (time) => {
        this.onTimeWokeUpChanged(time);
      },
    });
  }

  render() {
    if (!this.state.visible) {
      return "";
    }

    return (
      <div className={"modal-overlay"}>
        <div className={"modal-container"} id={"subject_data_container"}>
          <h1 className={"centered_title"}>Subject Information</h1>
          {this.state.hasErrors ? (
            <div className={"form-group has-errors"}>
              Some questions were left unanswered.
              <br />
              Please press Submit again to continue to the study as is.
            </div>
          ) : (
            ""
          )}
          <form
            onSubmit={(event) => {
              this.handleSubmit(event);
            }}
            id={"subject_data_form"}
          >
            <div
              className={
                this.state.errors["subjectID"]
                  ? "form-group has-errors"
                  : "form-group"
              }
            >
              <label htmlFor={"subjectID"}>Connect ID</label>
              <input
                type={"text"}
                value={this.state.subjectID}
                id={"subjectID"}
                className={"form-control"}
                onClick={(event) => {
                  event.target.select();
                }}
                onChange={(event) => {
                  this.onSubjectIDChanged(event);
                }}
              />
              <small id={"subjectIDHelp"} className={"form-text text-muted"}>
                Your subject id was provided to you in an email.
              </small>
              {this.state.errors["subjectID"] ? (
                <small
                  id={"subjectIDError"}
                  className={"form-text text-muted error-label"}
                >
                  You must provide your Subject ID to participate in the study.
                </small>
              ) : (
                ""
              )}
            </div>
            <HourPicker
              value={this.state.hoursSlept}
              interval={30}
              totalNumberOfMinutes={24 * 60}
              id={"hoursOfSleep"}
              title={"Hours of sleep last night"}
              caption={"How many hours of sleep did you get last night?"}
              onValueChanged={(value) => {
                this.onHoursSleptChanged(value);
              }}
              error={this.state.errors["hoursSlept"]}
            />
            <HourPicker
              value={this.state.usualTimeInBed}
              interval={30}
              totalNumberOfMinutes={24 * 60}
              id={"typicalTIB"}
              title={"How many hours of sleep do you typically get each night?"}
              caption={
                "On average, how many hours of sleep do you get each night?"
              }
              onValueChanged={(value) => {
                this.onUsualTimeInBedChanged(value);
              }}
              error={this.state.errors["usualTimeInBed"]}
            />
            <TimePicker
              value={this.state.timeWokeUp}
              interval={15}
              totalNumberOfMinutes={24 * 60}
              id={"timeWokeUp"}
              title={"Time Woke Up Today"}
              caption={
                "What time did you wake up this morning (H:MM 12hr format)?"
              }
              onValueChanged={(value) => {
                this.onTimeWokeUpChanged(value);
              }}
              error={this.state.errors["timeWokeUp"]}
            />
            <div
              className={
                this.state.errors["timezone"]
                  ? "form-group has-errors"
                  : "form-group"
              }
            >
              <label>Please select the timezone you are in.</label>
              <div className={"form-check-container"}>
                <TimezoneSelect
                  currentTimezone={this.state.currentTimezone}
                  onTimezoneChanged={this.onTimezoneChanged}
                />
              </div>
            </div>
            <div
              className={
                this.state.errors["caffeineToday"]
                  ? "form-group has-errors"
                  : "form-group"
              }
            >
              <label>Did you have caffeine today?</label>
              <br />
              <div className={"form-check-container"}>
                <div className={"form-check form-check-inline"}>
                  <input
                    type={"radio"}
                    name={"caffeineToday"}
                    id={"caffeineTodayYes"}
                    value={this.state.caffeineToday}
                    onChange={() => {
                      this.onCaffeineTodayChanged(true);
                    }}
                  />
                  <label
                    className={"form-check-label"}
                    htmlFor={"caffeineTodayYes"}
                  >
                    Yes
                  </label>
                </div>
                <div className={"form-check form-check-inline"}>
                  <input
                    type={"radio"}
                    name={"caffeineToday"}
                    id={"caffeineTodayNo"}
                    value={!this.state.caffeineToday}
                    onChange={() => {
                      this.onCaffeineTodayChanged(false);
                    }}
                  />
                  <label
                    className={"form-check-label"}
                    htmlFor={"caffeineTodayNo"}
                  >
                    No
                  </label>
                </div>
              </div>
            </div>
            <div
              className={
                this.state.errors["usuallyDrinkCaffeine"]
                  ? "form-group has-errors"
                  : "form-group"
              }
            >
              <label>Do you usually drink caffeine?</label>
              <br />
              <div className={"form-check-container"}>
                <div className={"form-check form-check-inline"}>
                  <input
                    className={"form-check-input"}
                    type={"radio"}
                    name={"usuallyDrinkCaffeine"}
                    id={"usuallyDrinkYes"}
                    value={this.state.usuallyDrinkCaffeine}
                    onChange={() => {
                      this.onUsuallyDrinkCaffeineChanged(true);
                    }}
                  />
                  <label
                    className={"form-check-label"}
                    htmlFor={"usuallyDrinkYes"}
                  >
                    Yes
                  </label>
                </div>
                <div className={"form-check form-check-inline"}>
                  <input
                    className={"form-check-input"}
                    type={"radio"}
                    name={"usuallyDrinkCaffeine"}
                    id={"usuallyDrinkNo"}
                    value={!this.state.usuallyDrinkCaffeine}
                    onChange={() => {
                      this.onUsuallyDrinkCaffeineChanged(false);
                    }}
                  />
                  <label
                    className={"form-check-label"}
                    htmlFor={"usuallyDrinkNo"}
                  >
                    No
                  </label>
                </div>
              </div>
            </div>
            <div
              className={
                this.state.errors["gamingKeyboard"]
                  ? "form-group has-errors"
                  : "form-group"
              }
            >
              <label>
                Are you using a gaming keyboard to complete this portion of the
                experiment?
              </label>
              <br />
              <div className={"form-check-container"}>
                <div className={"form-check form-check-inline"}>
                  <input
                    className={"form-check-input"}
                    type={"radio"}
                    name={"gamingKeyboard"}
                    id={"gamingKeyboardYes"}
                    value={this.state.gamingKeyboard}
                    onChange={() => {
                      this.onGamingKeyboardChanged("Yes");
                    }}
                  />
                  <label
                    className={"form-check-label"}
                    htmlFor={"gamingKeyboardYes"}
                  >
                    Yes
                  </label>
                </div>
                <div className={"form-check form-check-inline"}>
                  <input
                    className={"form-check-input"}
                    type={"radio"}
                    name={"gamingKeyboard"}
                    id={"gamingKeyboardNo"}
                    value={!this.state.gamingKeyboard}
                    onChange={() => {
                      this.onGamingKeyboardChanged("No");
                    }}
                  />
                  <label
                    className={"form-check-label"}
                    htmlFor={"gamingKeyboardNo"}
                  >
                    No
                  </label>
                </div>
                <div className={"form-check form-check-inline"}>
                  <input
                    className={"form-check-input"}
                    type={"radio"}
                    name={"gamingKeyboard"}
                    id={"gamingKeyboardUnsure"}
                    value={!this.state.gamingKeyboard}
                    onChange={() => {
                      this.onGamingKeyboardChanged("I Don't Know");
                    }}
                  />
                  <label
                    className={"form-check-label"}
                    htmlFor={"gamingKeyboardUnsure"}
                  >
                    I Don't Know
                  </label>
                </div>
              </div>
            </div>
            <div className={"submit_button_container"}>
              <input
                className={"subject_data_input subject_data_submit"}
                type={"submit"}
              />
            </div>
          </form>
          <ValidationFailedPopup
            showSubjectIDPrompt={true}
            hasErrors={this.state.showingErrorPopup}
            onPopupClosed={() => {
              this.onPopupClosed();
            }}
          />
        </div>
      </div>
    );
  }

  validateForm() {
    if (this.state.validated) {
      return true;
    }

    let newErrors = {
      subjectID: this.state.subjectID === "",
      hoursSlept: this.state.hoursSlept === "",
      caffeineToday: this.state.caffeineToday === "",
      usuallyDrinkCaffeine: this.state.usuallyDrinkCaffeine === "",
      usualTimeInBed: this.state.usualTimeInBed === "",
      timeWokeUp: this.state.timeWokeUp === "",
      gamingKeyboard: this.state.gamingKeyboard === "",
    };

    let hasErrors;

    for (const [_, hasError] of Object.entries(newErrors)) {
      if (hasError) {
        hasErrors = true;
        break;
      }
    }

    let subjectIDValid = newErrors["subjectID"] === false;

    let validated =
      (this.state.hasValidatedOnce || !hasErrors) && subjectIDValid;

    this.setState({
      hasValidatedOnce: true,
      errors: newErrors,
      hasErrors: hasErrors,
      validated: validated,
      showingErrorPopup: hasErrors,
    });

    return validated;
  }

  handleSubmit(event) {
    event.preventDefault();

    if (!this.validateForm()) {
      return;
    }

    let now = new Date();
    let dateTimeWokeUp = Date.parse(this.state.timeWokeUp);
    let hoursSinceWoke = Math.abs(now - dateTimeWokeUp) / 36e5;

    let subjectInfo = new SubjectInfo(
      this.state.subjectID,
      this.props.compensationCode,
      this.state.hoursSlept,
      hoursSinceWoke,
      this.state.caffeineToday,
      this.state.usuallyDrinkCaffeine,
      dateTimeWokeUp,
      this.state.usualTimeInBed,
      this.state.gamingKeyboard,
      this.state.currentTimezone
    );
    console.debug("subject data handleSubmit", this.state, subjectInfo);

    this.props.onSubjectInfoGathered(subjectInfo);

    this.setState({
      visible: false,
    });
  }

  onTimezoneChanged(timezone) {
    this.setState({
      currentTimezone: timezone.value,
    });
    console.debug(timezone, this.state.currentTimezone);
  }

  onSubjectIDChanged(event) {
    this.setState({
      subjectID: event.target.value,
    });
  }

  onHoursSleptChanged(value) {
    this.setState({
      hoursSlept: value,
    });
  }

  onHoursSinceWokeChanged(event) {
    this.setState({
      hoursSinceWoke: parseInt(event.target.value),
    });
  }

  parseTime(timeString) {
    if (timeString === "") return null;

    let time = timeString.match(/(\d+)(:(\d\d))?\s*(p?)/i);
    if (time == null) return null;

    let hours = parseInt(time[1], 10);

    if (hours === 12 && !time[4]) {
      hours = 0;
    } else {
      hours += hours < 12 && time[4] ? 12 : 0;
    }

    let parsedDateTime = new Date();

    parsedDateTime.setHours(hours);
    parsedDateTime.setMinutes(parseInt(time[3], 10) || 0);
    parsedDateTime.setSeconds(0, 0);

    return parsedDateTime;
  }

  onTimeWokeUpChanged(time) {
    this.setState({
      timeWokeUp: time,
    });
  }

  onUsualTimeInBedChanged(value) {
    this.setState({
      usualTimeInBed: value,
    });
  }

  onCaffeineTodayChanged(hadCaffeine) {
    this.setState({
      caffeineToday: hadCaffeine,
    });
  }

  onUsuallyDrinkCaffeineChanged(usuallyDrink) {
    this.setState({
      usuallyDrinkCaffeine: usuallyDrink,
    });
  }

  onGamingKeyboardChanged(gamingKeyboard) {
    this.setState({
      gamingKeyboard: gamingKeyboard,
    });
  }

  onPopupClosed() {
    this.setState({
      showingErrorPopup: false,
    });
  }
}

export class SubjectInfo {
  constructor(
    subjectID,
    compensationCode,
    hoursSlept,
    hoursSinceWoke,
    caffeineToday,
    usuallyDrinkCaffeine,
    timeWokeUp,
    usualTimeInBed,
    gamingKeyboard,
    timezone
  ) {
    this.subjectID = subjectID;
    this.compensationCode = compensationCode;
    this.hoursSlept = hoursSlept;
    this.hoursSinceWoke = hoursSinceWoke;
    this.caffeineToday = caffeineToday;
    this.usuallyDrinkCaffeine = usuallyDrinkCaffeine;
    this.timeWokeUp = timeWokeUp;
    this.usualTimeInBed = usualTimeInBed;
    this.gamingKeyboard = gamingKeyboard;
    this.viewDistanceMM = -1;
    this.pixelsToMM = -1;
    this.screenWidthPixels = -1;
    this.screenHeightPixels = -1;
    this.stimulusWidthPixels = -1;
    this.stimulusHeightPixels = -1;
    this.timezone = timezone;
  }

  toJSON(result_id) {
    let timeWokeUp = this.timeWokeUp;

    if (timeWokeUp !== null) {
      timeWokeUp = timeWokeUp.toISOString();
    } else {
      timeWokeUp = "";
    }

    return {
      result_id: result_id,
      subject_id: this.subjectID,
      compensation_code: this.compensationCode,
      hours_slept: this.hoursSlept,
      hours_since_woke: this.hoursSinceWoke,
      usual_time_in_bed: this.usualTimeInBed,
      caffeine_today: this.caffeineToday,
      usually_drink_caffeine: this.usuallyDrinkCaffeine,
      time_woke_up: timeWokeUp,
      gaming_keyboard: this.gamingKeyboard,
      view_distance_mm: this.viewDistanceMM,
      pixels_to_mm: this.pixelsToMM,
      screen_width_pixels: this.screenWidthPixels,
      screen_height_pixels: this.screenHeightPixels,
      stimulus_width_pixels: this.stimulusWidthPixels,
      stimulus_height_pixels: this.stimulusHeightPixels,
      timezone: this.timezone,
    };
  }
}
