import React from "react";
import PVTTestState from "./test_states";

export default function TestHeader(props) {
  return (
    <React.Fragment>
      <PracticeStartPrimer
        visible={props.practiceStartVisible && !props.uploadingTestResults}
      />
      <TrialStartPrimer
        visible={props.trialStartVisible && !props.uploadingTestResults}
        practiceAverage={props.practiceAverage}
      />
      <PracticeRunTitle
        visible={props.practiceRunVisible && !props.uploadingTestResults}
      />
      <UploadingDataPrimer visible={props.uploadingTestResults} />
    </React.Fragment>
  );
}

function PracticeStartPrimer(props) {
  if (props.visible) {
    return (
      <div className={"testPrimerContainer"}>
        The test will begin with 5 practice runs.
        <br />
        The practice runs will have the words "WAIT" and "Press the SPACEBAR" to
        help you practice the test. These words will not appear in the actual
        test.
        <br />
        To start the test, press the SPACEBAR.
      </div>
    );
  } else {
    return "";
  }
}

function PracticeRunTitle(props) {
  if (props.visible) {
    return (
      <div className={"practice-run-container"}>
        <h1>Practice Run</h1>
      </div>
    );
  } else {
    return "";
  }
}

function TrialStartPrimer(props) {
  if (props.visible) {
    return (
      <div className={"testPrimerContainer"}>
        Practice Completed.
        <br />
        Your average reaction time was: {props.practiceAverage.toFixed(2)}
        <br />
        You will now begin the test. Press the spacebar to continue. The test
        will automatically begin once you press the spacebar.
      </div>
    );
  } else {
    return "";
  }
}

function UploadingDataPrimer(props) {
  if (props.visible) {
    return (
      <div className={"testPrimerContainer uploadResultsContainer"}>
        Uploading Results and preparing experiment, please wait...
      </div>
    );
  } else {
    return "";
  }
}

export function PracticePrompt(props) {
  if (props.practiceRun === false) {
    return null;
  }

  if (
    props.currentState === PVTTestState.TEST_DELAY ||
    props.currentState === PVTTestState.TEST_POST_PRACTICE ||
    props.currentState === PVTTestState.TEST_FALSE_START ||
    props.currentState === PVTTestState.TEST_POST_REACTION
  ) {
    return (
      <div className={"practice-run-container practice-prompt-container"}>
        <h1>WAIT</h1>
      </div>
    );
  } else if (props.currentState === PVTTestState.TEST_REACTION) {
    return (
      <div className={"practice-run-container practice-prompt-container"}>
        <h1>Press the SPACEBAR</h1>
      </div>
    );
  } else {
    return null;
  }
}
