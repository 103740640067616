import React from "react";

import "../css/intermission_message.css";

export default function IntermissionMessagePopup(props) {
  if (props.visible) {
    return (
      <div id="intermission-message-popup" className="modal-overlay">
        <div id="intermission-message-container" className="modal-container">
          <h1 align="center">Experiment Intermission</h1>
          <p>
            You have successfully completed the PVT Test portion of this
            exercise. You will now complete several surveys about your
            experience. Please press continue when you are ready.
          </p>
          <div id="submit-button-container">
            <button
              id="submit-button"
              className="button"
              onClick={props.onContinueClicked}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    return "";
  }
}
