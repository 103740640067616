import React from "react";
import $ from "jquery";

function isScrolledIntoViewOfContainer(element, containerSelector) {
  let container = $(containerSelector);
  let elementTop = $(element).offset().top - container.offset().top;
  let elementBottom = elementTop + $(element).height();
  let containerHeight = container.height();
  return elementTop >= 0 && elementBottom <= containerHeight;
}

export default class HourPicker extends React.Component {
  constructor(props) {
    super(props);

    this.groupRef = React.createRef();
    this.buttonRef = React.createRef();
    this.menuRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.registerDropdownListener();
  }

  componentDidMount() {
    this.registerDropdownListener();
  }

  registerDropdownListener() {
    $(this.groupRef.current).on("shown.bs.dropdown", function () {
      console.log("Got dropdown shown event");

      let target = $(this).find(".dropdown-menu button.active");

      if (target.length === 0) {
        target = $(this).find('.dropdown-menu button[value="8"]');
      }

      if (target.length === 0) {
        console.log("Still couldn't find the thing");
      }

      if (isScrolledIntoViewOfContainer(target, ".dropdown-menu")) {
        console.log("Element already in view, ignoring");
      } else {
        let position =
          $(this).find(".dropdown-menu").scrollTop() +
          target.position().top -
          $(this).find(".dropdown-menu").height() / 2;

        $(".dropdown-menu").animate(
          {
            scrollTop: position,
          },
          100,
        );
      }
    });
  }

  generateHourPickerOptions() {
    let interval = this.props.interval;
    let totalNumberOfMinutes = this.props.totalNumberOfMinutes;
    let items = [];

    for (let i = 0; i <= totalNumberOfMinutes; i += interval) {
      let hours = i / 60;
      let optionText = hours.toString();
      let className = "dropdown-item";

      if (hours === this.props.value) {
        className += " active";
      }

      items.push(
        <button
          type={"button"}
          className={className}
          onClick={() => {
            this.props.onValueChanged(hours);
          }}
          value={hours}
        >
          {optionText}
        </button>,
      );
    }

    return items;
  }

  render() {
    return (
      <div
        className={this.props.error ? "form-group has-errors" : "form-group"}
        ref={this.groupRef}
      >
        <label htmlFor={this.props.id}>{this.props.title}</label>
        <button
          type={"button"}
          id={this.props.id}
          ref={this.buttonRef}
          className={"form-control dropdown-toggle"}
          data-toggle={"dropdown"}
          aria-haspopup={"true"}
          aria-expanded={"false"}
        >
          {this.props.value}
        </button>
        <div
          className={"dropdown-menu"}
          aria-labelledby={"hoursOfSleep"}
          ref={this.menuRef}
        >
          {this.generateHourPickerOptions()}
        </div>
        <small id={this.props.id + "Help"} className={"form-text text-muted"}>
          {this.props.caption}
        </small>
      </div>
    );
  }

  onValueChanged(value) {
    this.setState({
      value: value,
    });

    this.state.this.props.onValueChanged(value);
  }
}
