export default {
  TEST_SUBJECT_INFO: "test_subject_info",
  TEST_VIRTUAL_CHINREST: "test_virtual_chinrest",
  TEST_STOPPED: "test_stopped",
  TEST_DELAY: "test_delay",
  TEST_REACTION: "test_reaction",
  // This state signifies the 1 second pause where the timer is still displayed but the reaction has been measured already.
  TEST_POST_REACTION: "test_post_reaction",
  TEST_FALSE_START: "test_false_start",
  TEST_INTERMISSION: "test_intermission",
  TEST_MIND_WANDERING_QUESTIONNAIRE: "test_mind_wandering_questionnaire",
  TEST_TLX_QUESTIONNAIRE: "test_post_questionnaire",
  TEST_RESULTS_UPLOAD: "test_results_upload",
  TEST_RESULTS: "test_results",
  TEST_INSTRUCTIONS1: "test_instructions1",
  TEST_INSTRUCTIONS2: "test_instructions2",
  TEST_POST_PRACTICE: "test_post_practice",
};
