import React from "react";

export function ValidationFailedPopup(props) {
  if (props.hasErrors) {
    return (
      <div className={"modal-overlay"}>
        <div className={"modal-container"} id={"validation_failed_container"}>
          <h2 className={"centered_title"}>Validation Failed</h2>
          <p>Some questions were left unanswered.</p>
          <p>
            Please ensure all questions are answered, or press submit again to
            continue with the experiment.
          </p>
          {props.showSubjectIDPrompt ? (
            <p>Note that you must provide a Subject ID to continue.</p>
          ) : (
            ""
          )}
          <button
            id={"close_popup_button"}
            onClick={() => {
              props.onPopupClosed();
            }}
          >
            Ok
          </button>
        </div>
      </div>
    );
  } else {
    return "";
  }
}
